import style from './InviteFriend.module.scss';

// Images
import petsPhoto from '../../assets/img/invite-friend/1.png';

// React
import { useEffect } from 'react';

// Ant Design
import { message as antdMessage } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';

// Router
import { Link, useNavigate } from 'react-router-dom';

// Redux
import { useSelector } from 'react-redux';
import { Status } from '../../types/types';
import { useAppDispatch } from '../../redux/store';
import { selectInviteCode } from '../../redux/invite-code/selectors';
import { requestGetInviteCode } from '../../redux/invite-code/asyncActions';

// Components
import { BackButtonMain, ButtonMain } from '../../components/Buttons/Buttons';


const InviteFriend = () => {
    // Router
    const navigate = useNavigate();

    // Show Alert
    const [messageApi, contextHolder] = antdMessage.useMessage();

    // Redux
    const dispatch = useAppDispatch();

    const { invite_code, message, status } = useSelector(selectInviteCode);

    // Get Invite Code
    useEffect(() => {
        if (!invite_code && status === Status.CALM) {
            dispatch(requestGetInviteCode());
        }
    }, [dispatch, invite_code, status])

    useEffect(() => {
        if (status === Status.ERROR) {
            messageApi.error(message);
        }
    }, [status, message, messageApi])

    const clickBoardLink = (e: any) => {
        e.preventDefault();
        try {
            const boardLink = `https://t.me/${process.env.REACT_APP_TELEGRAM_USERNAME_BOT}?start=${invite_code}`;

            // Creating a temporary text field
            const textArea = document.createElement('textarea');
            textArea.value = boardLink;
            document.body.appendChild(textArea);

            // Selecting text in a field
            textArea.select();
            textArea.setSelectionRange(0, 99999); // Для мобильных устройств

            try {
                // Copy Text to clipboard
                const successful = document.execCommand('copy');
                const msg = successful ? 'Ссылка успешно скопирована' : 'Произошла ошибка при копировании ссылки';
                messageApi.info(msg, 2);
            } catch (err) {
                messageApi.error('Произошла ошибка при копировании ссылки', 2);
            }

            // Delete temporary text field
            document.body.removeChild(textArea);

        } catch (error) {
            messageApi.error('Произошла ошибка при копировании ссылки', 2);
        }
    }

    // Handler Back Button
    const handleClickBack = () => {
        navigate('/home/more');
    };

    return (
        <div className={style.invite_friend}>
            {contextHolder}
            <BackButtonMain handleFunction={handleClickBack} />
            <p>А мы предоставим вам услугу Видеоконсультация за каждого, кто приобретет услуги по вашему приглашению</p>
            <img src={petsPhoto} alt="pets" />
            <div onClick={clickBoardLink}>
                <ButtonMain text='Поделиться' />
            </div>
            <div className={style.how_work}>
                <h2>Как работает акция:</h2>
                <ol>
                    <li>Скопируйте ссылку</li>
                    <li>Отправьте ссылку любым удобным способом</li>
                    <li>Ваш друг приобретает любой пакет услуг</li>
                    <li>Через 14 дней и не позднее 20 дней вы получите указанную выше услугу за приведенного друга</li>
                </ol>
                <Link to={'/home/invite-friend/terms'}>
                    <p>Полные условия акции <ArrowRightOutlined /></p>
                </Link>
            </div>
        </div>
    )
}

export default InviteFriend;