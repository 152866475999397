import style from './About.module.scss';

// Router
import { useNavigate } from 'react-router-dom';

// Ant Design
import { RightCircleFilled } from '@ant-design/icons';

// Components
import { BackButtonMain } from '../../components/Buttons/Buttons';


const About = () => {
    const navigate = useNavigate();

    const handleClickBack = () => {
        navigate('/home/more');
    };

    return (
        <div className={style.about}>
            <BackButtonMain handleFunction={handleClickBack} />
            <p>VetPet - это приложение ветеринарной помощи онлайн. Наш ветеринар будет наблюдать за здоровьем твоего пушистого друга в течение того времени, которое ты выбрал!</p>
            <div className={style.about__links}>
                <a
                    target='_blank'
                    className={style.link}
                    href='https://vet-pet.online/docs/%D0%9E%D0%A4%D0%95%D0%A0%D0%A2%D0%90_%D0%9F%D0%A0%D0%90%D0%92%D0%98%D0%9B%D0%90_%D0%90%D0%91%D0%9E%D0%9D%D0%95%D0%9D%D0%A2%D0%A1%D0%9A%D0%9E%D0%93%D0%9E_%D0%9E%D0%91%D0%A1%D0%9B%D0%A3%D0%96%D0%98%D0%92%D0%90%D0%9D%D0%98%D0%AF_VETPET_%D0%A1%D0%90%D0%99%D0%A2_%D0%98_%D0%9C%D0%91_10_06.html'
                >
                    <p>Пользовательское соглашение</p>
                    <RightCircleFilled />
                </a>
                <a
                    target='_blank'
                    className={style.link}
                    href='https://vet-pet.online/docs/politika_konfidenczialnosti_vetpet-new.html'
                >
                    <p>Политика конфиденциальности</p>
                    <RightCircleFilled />
                </a>
            </div>
            <div className={style.about__app}>
                <p>Версия 0.0.1</p>
                <a target='_blank' href="https://millenial.group/">Сайт Разработчика</a>
            </div>
        </div>
    )
}

export default About;