
export enum Status {
    CALM = 'calm',
    LOADING = 'loading',
    SUCCESS = 'success',
    ERROR = 'error',
}

export interface getInviteReferralFormData {
    vkontakte_id: string | number;
}

export interface InviteSliceState {
    status: Status | string;
    message: string;
    invite_code: string | null;
    refferal_code: string | null;
}