import { useDispatch, useSelector } from 'react-redux';
import type {TypedUseSelectorHook} from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';

import pets from './pets/slice';
import auth from './auth/slice';
import chats from './chats/slice';
import purchase from './purchase/slice';
import payments from './payments/slice';
import packages from './packages/slice';
import petsForm from './pets-form/slice';
import documents from './documents/slice';
import promocode from './promocode/slice';
import invitecode from './invite-code/slice';
import myServices from './my-services/slice';
import myPackages from './my-packages/slice';

export const store = configureStore({
    reducer: {
        auth,
        pets,
        chats,
        purchase,
        payments,
        packages,
        petsForm,
        documents,
        promocode,
        invitecode,
        myPackages,
        myServices,
    },
})

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;