import style from './Profile.module.scss';

// React
import { useEffect, useState } from 'react';

// Routes
import { Link, useNavigate } from 'react-router-dom';

// Ant Design
import { RightOutlined } from '@ant-design/icons';

// Vkontakte
import { GetUserInfo } from '../home/Home';
import vkBridge from '@vkontakte/vk-bridge';

// Redux
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../redux/store';
import { selectMyPackages } from '../../redux/my-packages/selectors';
import { requestMyActivePackages, requestMyInactivePackages } from '../../redux/my-packages/asyncActions';

// Components
import Avatar from '../../components/Home/Avatar/Avatar';
import MyPets from '../../components/Profile/MyPets/MyPets';



const Profile = () => {
    const navigate = useNavigate();

    // Vkontakte
    const [user, setUser] = useState<GetUserInfo | void>();

    useEffect(() => {
        if (!user) {
          vkBridge.send('VKWebAppGetUserInfo')
          .then((data) => { 
            if (data.id) {
                setUser({
                    id: data.id,
                    firstName: data.first_name,
                    lastName: data.last_name,
                })
            }    
          })
        }
      }, [user])

    // Redux
    const dispatch = useAppDispatch();

    // Get Active / Inactive Packages
    const { status, active_packages, inactive_packages } = useSelector(selectMyPackages);

    useEffect(() => {
        if (!active_packages && !inactive_packages) {
            dispatch(requestMyActivePackages());
            dispatch(requestMyInactivePackages());
        }
    }, [active_packages, inactive_packages, dispatch, status])

    const handleClick = () => {
        navigate('/home');
    };

    const handleOpenLink = (e: any, href: string) => {
        e.preventDefault();
    }


    return (
        <div className={style.profile}>
            <div className={style.profile_info_block}>
                <div className={style.profile__header}>
                    <Avatar />
                    <p>{user?.id}</p>
                </div>
                <div className={style.profile__info_services}>
                    <Link to={'/home/chats'} className={style.profile__info_service_current}>
                        <div className={style.count_services}>
                            <p>{Array.isArray(active_packages) ? active_packages.length : active_packages}</p>
                        </div>
                        <p>Текущие услуги</p>
                    </Link>
                    <Link to={'/home/chats'} className={style.profile__info_service_archive}>
                        <div className={style.count_services}>
                            <p>{Array.isArray(inactive_packages) ? inactive_packages.length : inactive_packages}</p>
                        </div>
                        <p>Архив услуг</p>
                    </Link>
                </div>
                <MyPets />
            </div>
            <div className={style.profile_menu}>
                <Link to={'/home/documents'} className={style.profile_menu__link}>
                    <p>Документы</p>
                    <RightOutlined />
                </Link>
                <a
                    target='_blank'
                    className={style.profile_menu__link}
                    href='https://vet-pet.online/docs/%D0%9E%D0%A4%D0%95%D0%A0%D0%A2%D0%90_%D0%9F%D0%A0%D0%90%D0%92%D0%98%D0%9B%D0%90_%D0%90%D0%91%D0%9E%D0%9D%D0%95%D0%9D%D0%A2%D0%A1%D0%9A%D0%9E%D0%93%D0%9E_%D0%9E%D0%91%D0%A1%D0%9B%D0%A3%D0%96%D0%98%D0%92%D0%90%D0%9D%D0%98%D0%AF_VETPET_%D0%A1%D0%90%D0%99%D0%A2_%D0%98_%D0%9C%D0%91_10_06.html'
                >
                    <p>Пользовательское соглашение</p>
                    <RightOutlined />
                </a>
            </div>
        </div>
    )
}

export default Profile;