import style from './PetProfileMedicalCard.module.scss';

// React
import { useEffect, useState } from 'react';

// Redux
import { useAppDispatch } from '../../redux/store';
import { requestUpdatePet } from '../../redux/pets/asyncActions';
import { PetsCreateFormData } from '../../redux/pets-form/types';

// Router
import { useNavigate, useParams } from 'react-router-dom';

// Ant Design
import { Form, Input, Select } from 'antd';

// Components
import Spinner from '../../components/Spinner/Spinner';
import { BackButtonMain, ButtonMain } from '../../components/Buttons/Buttons';


const PetProfileMedicalCard = () => {
    let { petId } = useParams();
    const navigate = useNavigate();

    const petMedicalForm = localStorage.getItem('pet_medical_form');

    useEffect(() => {
        if (petMedicalForm) {
            const petMedicalFormData = JSON.parse(petMedicalForm);

            setChipNumber(petMedicalFormData.chip_number);
            setSterilization(petMedicalFormData.sterilization);
            setPeculiarities(petMedicalFormData.peculiarities);
            setPlaceDetention(petMedicalFormData.place_detention);
            setConditionsDetention(petMedicalFormData.conditions_detention);
        }
    }, [])

    // Redux
    const dispatch = useAppDispatch();


    // Sterilization
    const [sterilization, setSterilization] = useState<string>();

    const handleAddSexChange = (value: string) => {
        setSterilization(value);
    };

    // Place Detention
    const [placeDetention, setPlaceDetention] = useState<string>();

    // Conditions Detention
    const [conditionsDetention, setConditionsDetention] = useState<string>();

    // Chip number
    const [chipNumber, setChipNumber] = useState<string>();

    // Peculiarities
    const [peculiarities, setPeculiarities] = useState<string>();

    // Button Submit Disable
    const [disabled, setDisabled] = useState<boolean>(false);

    // Handle Add To LocalStorage and Redirect to Pets Add Form
    const handleSaveMedicalCardForm = (e: any) => {
        setDisabled(true);
        // Get Pet Form from Local Storage
        const petForm = localStorage.getItem('pet_form');
        if (petForm) {
            const parsePetForm = JSON.parse(petForm);
            const formDataUpdate: PetsCreateFormData = {
                id: Number(petId),
                name: parsePetForm.name,
                kind: parsePetForm.kind,
                breed: parsePetForm.breed,
                kind_name: parsePetForm.kind_name,
                breed_name: parsePetForm.breed_name,
                birth_date: parsePetForm.birth_date,
                weight: parsePetForm.weight,
                sex: parsePetForm.sex,
            }
            if (chipNumber) { formDataUpdate.nfc_code = chipNumber }
            if (peculiarities) { formDataUpdate.peculiar = peculiarities }
            if (placeDetention) { formDataUpdate.detention_place = placeDetention }
            if (conditionsDetention) { formDataUpdate.detention_conditions = conditionsDetention }
            if (sterilization) { formDataUpdate.sterilization = sterilization === 'Да'? true : false }

            dispatch(requestUpdatePet(formDataUpdate))
            setTimeout(() => {
                setDisabled(false);
                navigate(`/home/pets/profile/${petId}`);
            }, 1000)
        } else {
            setDisabled(false);
        }
    }

    const handleClickBack = () => {
        navigate(-1);
    }

    return (
        <div className={style.pet_profile_medical_card}>
            <BackButtonMain handleFunction={handleClickBack} />
            {petMedicalForm && petId
                ? (
                    <Form
                        labelCol={{ span: 4 }}
                        wrapperCol={{ span: 10 }}
                        layout="horizontal"
                        style={{ maxWidth: 300, margin: '0 auto' }}
                    >
                        <Form.Item style={{ minWidth: '140px' }} className={style.sterilization}>
                            <Select
                                value={sterilization}
                                placeholder='Стерелизация'
                                onSelect={(value) => handleAddSexChange(value)}
                            >
                                <Select.Option value="Да">Да</Select.Option>
                                <Select.Option value="Нет">Нет</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item className={style.place}>
                            <Input
                                value={placeDetention}
                                placeholder='Место содержания'
                                onChange={(e) => setPlaceDetention(e.target.value)}
                            />
                        </Form.Item>
                        <Form.Item className={style.conditions}>
                            <Input
                                value={conditionsDetention}
                                placeholder='Условия содержания'
                                onChange={(e) => setConditionsDetention(e.target.value)}
                            />
                        </Form.Item>
                        <Form.Item className={style.chip_number}>
                            <Input
                                value={chipNumber}
                                placeholder='Номер чипа'
                                onChange={(e) => setChipNumber(e.target.value)}
                            />
                        </Form.Item>
                        <Form.Item className={style.peculiarities}>
                            <Input
                                value={peculiarities}
                                placeholder='Особенности'
                                onChange={(e) => setPeculiarities(e.target.value)}
                            />
                        </Form.Item>
                        <div onClick={handleSaveMedicalCardForm} className={style.button_medical_form}>
                            <ButtonMain disabled={disabled} text='Сохранить' />
                        </div>
                    </Form>
                )
                : <Spinner />}
        </div>
    )
}

export default PetProfileMedicalCard;