import style from './Support.module.scss';

// React
import { useState } from 'react';

// Ant Design
import { MailFilled, PhoneFilled } from '@ant-design/icons';

// Router
import { useNavigate } from 'react-router-dom';

// Components
import Question from '../../components/Home/Question/Question';
import { BackButtonMain } from '../../components/Buttons/Buttons';


const Support = () => {
    const [isFirstQuestion, setFirstQuestion] = useState<boolean>(false);
    const [isSecondQuestion, setSecondQuestion] = useState<boolean>(false);
    const [isThirdQuestion, setThirdQuestion] = useState<boolean>(false);

    type QuestionsType = {
        title: string;
        description: string;
        value: boolean;
        action: React.Dispatch<React.SetStateAction<boolean>>;
    }

    const questions: QuestionsType[] = [
        {
            title: 'Скорость ответа',
            description: 'Заявка приходит ветеринару автоматически. В течении 30 минут с Вами свяжется наш специалист, если Вы решили получить консультацию в устной или письменной форме. Если консультация в формате видео, то обратную связь от нашего сервиса Вы получаете в то время или день, которые вы выбрали сами.',
            value: isFirstQuestion,
            action: setFirstQuestion,
        },
        {
            title: 'На какие вопросы мы не отвечаем?',
            description: '<div>1. Вопросы, заданные на другом языке, кроме русского.<br /><br />2. На вопросы, нарушающие общепринятые правила общения. Если в формулировке обращения присутствуют элементы оскорбляющих высказываний / выражений и нецензурной лексики в адрес специалиста.<br /><br />3. Вопросы, требующие справочной информации или прогноза. Например, что такое гастрит у кошки? Или "Гастрит у кошки - это страшно?". Ответ на первый вопрос можно найти в любой поисковой системе без помощи специалистов, если этот вопрос не идет в разрез со здоровьем Вашего питомца. На втором примере вопроса специалисты нашего сервиса не могут дать точных прогнозов заочно, и, если Вы не предоставили примеры лабораторных обследований.<br /><br />4. Если Ваш вопрос содержит просьбу посоветовать методы народного лечения. Консультации наших специалистов базируется на основе доказательной медицины.</div>',
            value: isSecondQuestion,
            action: setSecondQuestion,
        },
        {
            title: 'Чем мне могут помочь ваши онлайн консультации?',
            description: 'Если жизнь Вашего питомца под угрозой, то очное посещение клиники конечно же не заменит онлайн консультацию.',
            value: isThirdQuestion,
            action: setThirdQuestion,
        }
    ]

    const navigate = useNavigate();

    const handleClickBack = () => {
        navigate('/home/more');
    };

    return (
        <div className={style.support}>
            <BackButtonMain handleFunction={handleClickBack} />
            <h2>Часто задаваемые вопросы</h2>
            <p>Найдите ответ на свой вопрос</p>
            <div className={style.questions}>
                {questions.map((item) => <Question key={item.title} title={item.title} description={item.description} value={item.value} action={item.action} />)}
            </div>
            <div className={style.service_support}>
                <h2>Служба поддержки</h2>
                <p>Свяжитесь с нами любым удобным способом</p>
                <div className={style.service_support__contacts}>
                    <div>
                        <MailFilled />
                        <p>office@vet-pet.online</p>
                    </div>
                    <div>
                        <PhoneFilled />
                        <p>8 800 600 99 93</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Support;