import style from './ServicesPay.module.scss';

// React
import { useEffect, useState } from 'react';

// Router
import { useNavigate, useParams } from 'react-router-dom';

// Date
import moment from 'moment';

// Ant Design
import { message as antdMessage } from 'antd';

// VK
import vkBridge from '@vkontakte/vk-bridge';

// Redux
import { useSelector } from 'react-redux';
import { Status } from '../../types/types';
import { useAppDispatch } from '../../redux/store';
import { setPackageByAlias } from '../../redux/packages/slice';
import { selectPackages } from '../../redux/packages/selectors';
import { PromoCodeFormData } from '../../redux/promocode/types';
import { selectPromoCode } from '../../redux/promocode/selectors';
import { requestActivatePromoCode } from '../../redux/promocode/asyncActions';

// Components
import { GetUserInfo } from '../home/Home';
import Spinner from '../../components/Spinner/Spinner';
import { BackButtonMain } from '../../components/Buttons/Buttons';
import { selectPurchase } from '../../redux/purchase/selectors';
import { requestPurchase } from '../../redux/purchase/asyncActions';
import { PurchaseFormData } from '../../redux/purchase/types';
import { setPromocodeIsNull } from '../../redux/promocode/slice';
import { setPurchaseIsNull } from '../../redux/purchase/slice';


const ServicesPay = () => {
    let { alias } = useParams();
    const navigate = useNavigate();
    const [promocode, setPromocode] = useState<string>('');

    // User
    const [user, setUser] = useState<GetUserInfo | void>();

    useEffect(() => {
        if (!user) {
            vkBridge.send('VKWebAppGetUserInfo')
                .then((data) => {
                    if (data.id) {
                        setUser({
                            id: data.id,
                            firstName: data.first_name,
                            lastName: data.last_name,
                        })
                    }
                })
        }
    }, [user])

    // Redux
    const dispatch = useAppDispatch();
    const { activePackage } = useSelector(selectPackages);

    useEffect(() => {
        if (alias) {
            dispatch(setPackageByAlias({ alias: alias }))
        }
    }, [dispatch, alias])

    // Show Alert
    const [messageApi, contextHolder] = antdMessage.useMessage();

    // Promocode
    const { status, message, promo_package } = useSelector(selectPromoCode);

    // Activate PromoCode
    const handlerActivatePromocode = (e: any) => {
        e.preventDefault();
        e.target.disabled = true;

        // Activate PromoCode
        if (promo_package) {
            messageApi.info('Вы уже активировали промокод', 2);
            e.target.disabled = false;
            return;
        }

        if (promocode.length === 0) {
            messageApi.error('Пожалуйста введите промокод', 2);
            e.target.disabled = false;
            return;
        }

        if (alias) {
            const formData: PromoCodeFormData = {
                promocode: promocode,
                service: alias,
            }
            dispatch(requestActivatePromoCode(formData));
        }
        e.target.disabled = false;
    }

    useEffect(() => {
        if (status !== Status.CALM && message.length > 0) {
            if (status === Status.SUCCESS) {
                messageApi.success(message, 2);
                return;
            }
            if (status === Status.ERROR) {
                messageApi.error(message, 2);
                return;
            }
        }
    }, [status, message, messageApi])

    // Purchase
    const { messagePurchase, statusPurchase } = useSelector(selectPurchase);

    // Payment Handler
    const payment_handler = () => {
        if (activePackage && user?.id) {
            vkBridge.send('VKWebAppOpenPayForm', {
                'app_id': 52073455,
                "action": "pay-to-user",
                "params": {
                    amount: promo_package ? promo_package.price : activePackage.price,
                    // amount: 1,
                    user_id: 638746546,
                }
            })
            .then((data: any) => {
                if (data.status === 'success') {
                    // Register Purchase
                    const formDataPurchase: PurchaseFormData = {
                        package_alias: activePackage.alias,
                        vkontakte_id: user.id,
                    }
                    dispatch(requestPurchase(formDataPurchase));
                }
            })
            .catch((error) => {
                // Ошибка
                console.log(error);
            })
        }
    }

    useEffect(() => {
        if (statusPurchase === Status.SUCCESS && messagePurchase.length > 0) {
            messageApi.success(messagePurchase, 2);
            navigate(`/home/`);
        }

        if (statusPurchase === Status.ERROR && messagePurchase.length > 0) {
            messageApi.error(messagePurchase, 2);
        }

        return () => {
            dispatch(setPurchaseIsNull());
            dispatch(setPromocodeIsNull());
        }
    }, [statusPurchase, messagePurchase, messageApi, dispatch])

    // Button Back
    const handleClickBack = () => {
        navigate(`/home/service/${alias}`);
    };

    return (
        <div className={style.services_pay}>
            <BackButtonMain handleFunction={handleClickBack} />
            {contextHolder}
            {!activePackage
                ? (
                    <Spinner />
                )
                : (
                    <>
                        <div className={style.services_pay__price}>
                            <h2>К оплате: {promo_package ? promo_package.price : activePackage?.price}</h2>
                        </div>
                        <div className={style.services_pay__promocode}>
                            <p>Есть промокод?</p>
                            <form>
                                <input
                                    type="text"
                                    value={promocode}
                                    onChange={(e) => setPromocode(e.target.value)} />
                                <button onClick={handlerActivatePromocode}>Применить</button>
                            </form>
                        </div>
                        <div className={style.services_pay__btn}>
                            <button onClick={payment_handler}>Оплатить</button>
                        </div>
                    </>
                )
            }
        </div>
    )
}

export default ServicesPay;