import style from './Navbar.module.scss';

// Routes
import { Link, useLocation } from 'react-router-dom';

// Images
import petsIcon from '../../assets/img/navbar/1.svg';
import petsIconTwo from '../../assets/img/navbar/1_1.svg';
import companyIcon from '../../assets/img/navbar/3.png';

// Ant Design
import { EllipsisOutlined, HomeOutlined, WechatOutlined } from '@ant-design/icons';


const Navbar = () => {
    const { pathname } = useLocation();

    return (
        <nav className={style.navbar}>
            <Link to={'/home'}
                  className={
                    pathname === '/' ||
                    pathname === '/home' ||
                    pathname === '/home/profile' ? style.active : ''}
            >
                <HomeOutlined />
                <p>Главная</p>
            </Link>
            <Link to={'/home/chats'} className={pathname === '/home/chats' ? style.active : ''}>
                <WechatOutlined />
                <p>Чаты</p>
            </Link>
            <div className={style.logo}>
                <div className={style.circle}>
                    <img src={companyIcon} alt="icon-company" />
                </div>
            </div>
            <Link to={'/home/pets'}
                  className={
                    pathname === '/home/pets' ||
                    pathname === '/home/pets/add' ||
                    pathname === '/home/pets/add/medical_card' ||
                    pathname.includes('/home/pets/profile') ? style.active : ''}
            >
                <img src={
                        pathname === '/home/pets' ||
                        pathname === '/home/pets/add' ||
                        pathname === '/home/pets/add/medical_card' ||
                        pathname.includes('/home/pets/profile') ||
                        pathname.includes('pets/medical_card/profile') ? petsIconTwo : petsIcon}
                     alt="pets-icon"
                />
                <p>Питомцы</p>
            </Link>
            <Link to={'/home/more'}
                  className={
                    pathname === '/home/more' ||
                    pathname === '/home/invite-friend' ||
                    pathname === '/home/invite-friend/terms' ? style.active : ''}>
                <EllipsisOutlined />
                <p>Еще</p>
            </Link>
        </nav>
    )
}

export default Navbar;