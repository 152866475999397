import './assets/css/App.scss';

// Router
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// Layouts
import { TitleLayout, WithoutNavbarLayout } from './layouts/layouts';

// Redux
import { ReduxProvider } from './redux/provider';

// Components
import Home from './pages/home/Home';
import Pets from './pages/pets/Pets';
import More from './pages/more/More';
import Chat from './pages/chat/Chat';
import About from './pages/about/About';
import Chats from './pages/chats/Chats';
import Support from './pages/support/Support';
import Profile from './pages/profile/Profile';
import PetsAdd from './pages/pets-add/PetsAdd';
import Documents from './pages/documents/Documents';
import PetProfile from './pages/pet-profile/PetProfile';
import ServiceInfo from './pages/service-info/ServiceInfo';
import ServicesPay from './pages/services-pay/ServicesPay';
import InviteFriend from './pages/invite-friend/InviteFriend';
import TermsOfAction from './pages/terms_of_action/TermsOfAction';
import PetsAddMedical from './pages/pets-add-medical/PetsAddMedical';
import PetProfileMedicalCard from './pages/pet-profile-medical-card/PetProfileMedicalCard';
import Welcome from './pages/welcome/Welcome';


function App() {  
  return (
    <ReduxProvider>
      <BrowserRouter>
        <Routes>
          <Route path='/home' element={<TitleLayout />}>
            <Route path='/home' element={<Home />} />
            <Route path='/home/pets/profile/:petId' element={<PetProfile />} />
          </Route>
        
          <Route path='/home' element={<TitleLayout />}>
            <Route index element={<Home />} />
            <Route path='pets' element={<Pets />} />
            <Route path='more' element={<More />} />
            <Route path='chats' element={<Chats />} />
            <Route path='profile' element={<Profile />} />
            <Route path='invite-friend/terms' element={<TermsOfAction />} />
            <Route path='invite-friend' element={<InviteFriend />} />
            <Route path='documents' element={<Documents />} />
            <Route path='pets/add' element={<PetsAdd />} />
            <Route path='pets/add/medical_card' element={<PetsAddMedical />} />
            <Route path='pets/medical_card/profile/:petId' element={<PetProfileMedicalCard />} />
          </Route>

          <Route path='/home' element={<WithoutNavbarLayout />}>
            <Route path='support' element={<Support />} />
            <Route path='about' element={<About />} />
            <Route path='service/:alias' element={<ServiceInfo />} />
            <Route path='service-pay/:alias' element={<ServicesPay />} />
          </Route>

          <Route path='/home/chat/:chatId/:type' element={<Chat />} />
          <Route path='/' element={<Welcome />} />
        </Routes>
      </BrowserRouter>
    </ReduxProvider>
  );
}

export default App;
