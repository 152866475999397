import { createAsyncThunk } from '@reduxjs/toolkit';

import { AuthFormData } from '../auth/types';
import AuthService from '../../services/AuthService';


export const requestAuth = createAsyncThunk('/telegram-web-app/v1/auth/vkontakte', async (formData: AuthFormData) => {
    const response = await AuthService.auth(formData);
    return response;
})
