import style from './Buttons.module.scss';

import { LeftOutlined } from '@ant-design/icons';

type PropsMainBtn = {
    text: string;
    disabled?: boolean;
}

export const ButtonMain = ({ text, disabled = false }: PropsMainBtn) => {
    return (
        <button disabled={disabled} className={style.main_btn}>{text}</button>
    )
}

type PropsBackMainBtn = {
    handleFunction: () => void;
}


export const BackButtonMain = ({ handleFunction }: PropsBackMainBtn) => {
    return (
        <div className={style.main_back_btn} onClick={handleFunction}>
            <LeftOutlined />
        </div>
    )
}