import style from './Documents.module.scss';

// React
import { useEffect } from 'react';

// Ant Design
import { FileFilled } from '@ant-design/icons';

// Router
import { useNavigate } from 'react-router-dom';

// Redux
import { useSelector } from 'react-redux';
import { Status } from '../../types/types';
import { useAppDispatch } from '../../redux/store';
import { selectDocuments } from '../../redux/documents/selectors';
import { requestGetMyDocuments } from '../../redux/documents/asyncActions';

// Components
import Spinner from '../../components/Spinner/Spinner';
import EmptyPage from '../../components/EmptyPage/EmptyPage';
import { BackButtonMain } from '../../components/Buttons/Buttons';


const Documents = () => {
    // Router
    const navigate = useNavigate();

    // Redux
    const dispatch = useAppDispatch();
    const { status, documents } = useSelector(selectDocuments);

    // Get Documents
    useEffect(() => {
        if (!documents.length && status === Status.CALM) {
            dispatch(requestGetMyDocuments())
        }
    }, [documents, status, dispatch])

    const handleClickBack = () => {
        navigate('/home/profile');
    };

    return (
        <div className={style.documents}>
            <BackButtonMain handleFunction={handleClickBack} />
            {status === Status.LOADING 
                ? <Spinner />
                : (
                    <>
                        {documents.length > 0
                            ? (
                                <div className={style.my_documents}>
                                    {documents.map((document: any) => (
                                        <div className={style.document} key={document.id}>
                                            <FileFilled />
                                            <a href={document.link}>{document.value}</a>
                                        </div>
                                    ))}
                                </div>
                            )
                            : <EmptyPage title='' text='Здесь будут храниться документы, использованные в чатах' />
                        }
                    </>
                )
            }
        </div>
    )
}

export default Documents;