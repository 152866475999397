import style from './Home.module.scss';

// Images
import pawAnimal from '../../assets/img/home/mdi--paw.svg';

// React
import { useEffect, useState } from 'react';

// VK
import vkBridge from '@vkontakte/vk-bridge';

// Ant Design
import { CalendarOutlined } from '@ant-design/icons';

// Components
import MenuLink from '../../components/Home/MenuLink/MenuLink';
import Packages from '../../components/Home/Packages/Packages';
import AvatarComponent from '../../components/Home/Avatar/Avatar';


export interface GetUserInfo {
    id: number;
    firstName: string;
    lastName: string;
}


const Home = () => {
    const [user, setUser] = useState<GetUserInfo | void>();

    useEffect(() => {
        if (!user) {
          vkBridge.send('VKWebAppGetUserInfo')
          .then((data) => { 
            if (data.id) {
                setUser({
                    id: data.id,
                    firstName: data.first_name,
                    lastName: data.last_name,
                })
            }    
          })
        }
      }, [user])

    return (
        <div className={style.home_page}>
           <div className={style.home_page__action_block}>
                <div className={style.home__user_block}>
                    <AvatarComponent href='/home/profile' />
                    <p className={style.name}>{user?.firstName}</p>
                </div>
                <div className={style.home__menu_block}>
                    <MenuLink href={'/home/chats'} icon={<CalendarOutlined />} text='Выбрать консультацию' />
                    <MenuLink href={'/home/pets'} icon={<img src={pawAnimal} alt="paw" />} text='Добавить питомца' />
                </div>
            </div>
            <div className={style.home_page__services_block}>
                <h1>Услуги</h1>
                <div className={style.home_page__services}>
                <Packages />
                </div>
            </div>
        </div>
    )
}

export default Home;