import style from './Messages.module.scss';

// Ant Design
import { Empty } from 'antd';

// React
import { useEffect, useState } from 'react';

// Redux
import { useSelector } from 'react-redux';
import { Status } from '../../../types/types';
import { ChatData } from '../../../redux/chats/types';
import { selectMyChats } from '../../../redux/chats/selectors';

// Components
import Spinner from '../../Spinner/Spinner';
import { MessageTextReceiver, MessageTextSender } from '../MessageText/MessageText';
import { MessageFileReceiver, MessageFileSender } from '../MessageFile/MessageFile';
import { MessageImageReceiver, MessageImageSender } from '../MessageImage/MessageImage';


type Props = {
    activeChat: ChatData | null;
    messagesEndRef: React.MutableRefObject<HTMLDivElement | null>;
    scrollBottom: () => void;
}

const Messages = ({ activeChat, messagesEndRef, scrollBottom }: Props) => {
    const [hasScrolled, setHasScrolled] = useState<boolean>(false);

    const { statusChat } = useSelector(selectMyChats);

    // Scroll Bottom
    useEffect(() => {
        if (!hasScrolled && statusChat === Status.SUCCESS && activeChat && activeChat?.messages.length > 0) {
            setTimeout(() => {
                scrollBottom();
                setHasScrolled(true);
            }, 1000)
        }
    }, [hasScrolled, statusChat, activeChat]);
    return (
        <>
            {!activeChat
                ? (<Spinner />)
                : (
                    <>
                        {activeChat && activeChat.messages.length > 0
                            ? (
                                activeChat.messages.map((msg, idx) => (
                                    msg.type === 'text' ? (
                                        msg.author === 'Менеджер'
                                            ? (
                                                <MessageTextReceiver text={msg.value} key={idx} />
                                            )
                                            : (
                                                <MessageTextSender text={msg.value} key={idx} />
                                            )
                                    ) : msg.type === 'image' ? (
                                        msg.author === 'Менеджер'
                                            ? (
                                                <MessageImageReceiver message={msg} key={idx} />
                                            )
                                            : (
                                                <MessageImageSender message={msg} key={idx} />
                                            )
                                    ) : msg.type === 'file' ? (
                                        msg.author === 'Менеджер'
                                            ? (
                                                <MessageFileReceiver message={msg} key={idx} />
                                            )
                                            : (
                                                <MessageFileSender message={msg} key={idx} />
                                            )
                                    ) : <></>
                                ))
                            )
                            : <Empty className={style.chat__empty} description={<span>Нет сообщений</span>} />}
                            <div ref={messagesEndRef} />
                    </>
                )
            }
        </>
    )
}

export default Messages;