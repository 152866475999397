import style from './ServiceInfo.module.scss';

// Redux
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../redux/store';
import { setActiveIsNull } from '../../redux/packages/slice';
import { selectPackages } from '../../redux/packages/selectors';

// VK
import vkBridge from '@vkontakte/vk-bridge';

// Router
import { useNavigate } from 'react-router-dom';

// Component
import Spinner from '../../components/Spinner/Spinner';
import { BackButtonMain, ButtonMain } from '../../components/Buttons/Buttons';
import { selectInviteCode } from '../../redux/invite-code/selectors';
import { selectAuth } from '../../redux/auth/selectors';
import { Status } from '../../types/types';
import { getInviteReferralFormData } from '../../redux/invite-code/types';
import { requestGetInviteReferral } from '../../redux/invite-code/asyncActions';
import { useEffect, useState } from 'react';
import { GetUserInfo } from '../home/Home';


const ServiceInfo = () => {
    const [user, setUser] = useState<GetUserInfo | void>();

    useEffect(() => {
        if (!user) {
          vkBridge.send('VKWebAppGetUserInfo')
          .then((data) => { 
            if (data.id) {
                setUser({
                    id: data.id,
                    firstName: data.first_name,
                    lastName: data.last_name,
                })
            }    
          })
        }
      }, [user])

    const navigate = useNavigate();

    // Redux
    const dispatch = useAppDispatch();
    const { activePackage } = useSelector(selectPackages);

    // Get Referral Code
    const { refferal_code } = useSelector(selectInviteCode);

    const { statusAuth } = useSelector(selectAuth);

    useEffect(() => {
        if (statusAuth === Status.SUCCESS && !refferal_code && user) {
            const formData: getInviteReferralFormData = {
                vkontakte_id: user.id
            }
            dispatch(requestGetInviteReferral(formData));
        }
    }, [dispatch, user, statusAuth, refferal_code])

    const handleClickBack = () => {
        dispatch(setActiveIsNull());
        navigate('/home');
    };

    const handleClickGoCheckout = () => {
        navigate(`/home/service-pay/${activePackage?.alias}`);
    }

    return (
        <div className={style.service_info}>
            <BackButtonMain handleFunction={handleClickBack} />
            {activePackage
                ? (
                    <>
                        <h1>{activePackage.verbose_name}</h1>
                        <img src={activePackage.image_thumbnail} alt="img-service" />
                        <p>{activePackage.price} рублей</p>
                        
                        <div onClick={handleClickGoCheckout}>
                            <ButtonMain text='Добавить' />
                        </div>
                    </>
                )
                : (
                    <Spinner />
                )}
        </div>
    )
}

export default ServiceInfo;