import style from './MessageFile.module.scss';

// Redux
import { Message } from '../../../redux/chats/types';


type Props = {
    message: Message;
}

export const MessageFileSender = ({ message }: Props) => {
    return (
        <div className={style.send_message_file}>
            <p>{message.value}</p>
            <a target='_blank' href={message.link}>Скачать Документ</a>
        </div>
    )
}


export const MessageFileReceiver = ({ message }: Props) => {
    return (
        <div className={style.receiver_message_file}>
            <p>{message.value}</p>
            <a target='_blank' href={message.link}>Скачать Документ</a>
        </div>
    )
}