import style from './AudioRecord.module.scss';

// React
import React from 'react';

// Ant Design
import { DeleteOutlined } from '@ant-design/icons';

// Components
import AudioTimer from '../AudioTimer/AudioTimer';


type Props = {
    isRunning: boolean;
    elapsedTime: number;
    recordBlobLink: any;
    setElapsedTime: React.Dispatch<React.SetStateAction<number>>;
    clearHandle: () => void;
}

const AudioRecord = (
    {isRunning,
     elapsedTime,
     recordBlobLink,
     setElapsedTime,
     clearHandle }: Props) => {

    return (
        <>
            <div className={style.audio_record}>
                {!recordBlobLink && (
                    <AudioTimer
                        isRunning={isRunning}
                        elapsedTime={elapsedTime}
                        setElapsedTime={setElapsedTime}
                    />
                )}
                {recordBlobLink && <DeleteOutlined onClick={clearHandle} />}
                {recordBlobLink && <audio controlsList='noplaybackrate nodownload' src={recordBlobLink} controls></audio>}
            </div>
        </>
    );
};

export default AudioRecord;