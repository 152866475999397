import style from './MyPets.module.scss';

// Images
import imgAnimal from '../../../assets/img/pets/cil-animal.svg';

// React
import { useEffect } from 'react';

// Router
import { Link } from 'react-router-dom';

// Redux
import { useSelector } from 'react-redux';
import { Status } from '../../../types/types';
import { useAppDispatch } from '../../../redux/store';
import { selectPets } from '../../../redux/pets/selectors';
import { requestGetMyPets } from '../../../redux/pets/asyncActions';

// Ant Design
import { PlusCircleFilled } from '@ant-design/icons';


const MyPets = () => {
    // Redux
    const dispatch = useAppDispatch();
    const { status, pets } = useSelector(selectPets);

    useEffect(() => {
        if (status === Status.CALM && pets.length === 0) {
            dispatch(requestGetMyPets())
        }
    }, [dispatch, pets])

    return (
        <div className={style.profile__pets}>
            <h1>Мои питомцы</h1>
            <div className={style.profile__pets_pets} style={pets.length === 0 ? {justifyContent: 'center'} : {}}>
                {pets.length > 0 && pets.map((pet, idx) => (
                    <Link to={`/home/pets/profile/${pet.id}`} key={idx} className={style.profile__pets_pet}>
                        <div className={style.pet_img}>
                            <img src={imgAnimal} alt={`animal-${pet.id}`} />
                        </div>
                        <p>{pet.name}</p>
                    </Link>
                ))}
                <Link to={'/home/pets'}>
                    <PlusCircleFilled />
                </Link>
            </div>
        </div>
    )
}

export default MyPets;