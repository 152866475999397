import style from './MessageImage.module.scss';

// Ant Design
import { Image, Space } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

// Redux
import { Message } from '../../../redux/chats/types';


type Props = {
    message: Message;
}

export const MessageImageSender = ({ message }: Props) => {
    return (
        <div className={style.chat__send_message_img}>
            <Image
                width={'100%'}
                src={message.link}
                preview={{
                    toolbarRender: (
                        _,
                    ) => (
                        <Space size={12} className="toolbar-wrapper">
                            <a target='_blank' href={message.link}>
                                <DownloadOutlined style={{ fontSize: 40, color: '#b6b5b5' }} />
                            </a>
                        </Space>
                    ),
                }}
            />
        </div>
    )
}

export const MessageImageReceiver = ({ message }: Props) => {
    return (
        <div className={style.chat__receiver_message_img}>
            <Image
                width={'100%'}
                src={message.link}
                preview={{
                    toolbarRender: (
                        _,
                    ) => (
                        <Space size={12} className="toolbar-wrapper">
                            <a target='_blank' href={message.link}>
                                <DownloadOutlined style={{ fontSize: 40, color: '#b6b5b5' }} />
                            </a>
                        </Space>
                    ),
                }}
            />
        </div>
    )
}